var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.fair)?_c('fair-layout',{attrs:{"fair-id":_vm.fair.id},scopedSlots:_vm._u([{key:"banner",fn:function(){return [(_vm.fair)?_c('banner',{attrs:{"title":_vm.fair.name,"type":"banner--admin","options":{ padding: '30px' }}}):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"px-7"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex align-center justify-space-between"},[_c('h2',[_vm._v("Configuración de Sitios de Interes")]),_c('v-btn',{staticClass:"text--primary text-none elevation-0",attrs:{"color":"secondary","dark":""},on:{"click":function($event){return _vm.openInterestPlaceModal()}}},[_vm._v(" Nuevo Sitio de Interes ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('data-table',{ref:"interestPlaces",attrs:{"url":_vm.url,"params":_vm.params,"headers":_vm.headers,"withoutPagination":true,"mobile":true},scopedSlots:_vm._u([{key:"item.urlWebsite",fn:function(ref){
var item = ref.item;
return [(item)?_c('a',{attrs:{"href":item}},[_vm._v("Ver sitio web")]):_vm._e()]}},{key:"item.urlGoogleMap",fn:function(ref){
var item = ref.item;
return [(item)?_c('a',{attrs:{"href":item}},[_vm._v("Ver ubicación en Google Maps")]):_vm._e()]}},{key:"item.urlWazeMap",fn:function(ref){
var item = ref.item;
return [(item)?_c('a',{attrs:{"href":item}},[_vm._v("Ver ubicación en Waze")]):_vm._e()]}},{key:"item.arrows",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.last)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"large":"","color":"black","center":""},on:{"click":function($event){return _vm.onChangeOrderInterestPlace(item, 0)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-angle-down ")]):_c('v-icon',{staticClass:"ml-10 pl-1",attrs:{"large":""}})]}}],null,true)},[_c('span',[_vm._v("Subir Posición")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.first)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"large":"","color":"black","center":""},on:{"click":function($event){return _vm.onChangeOrderInterestPlace(item, 1)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-angle-up ")]):_c('v-icon',{staticClass:"ml-5",attrs:{"large":""}})]}}],null,true)},[_c('span',[_vm._v("Bajar Posición")])])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"ma-0",attrs:{"dense":"","hide-details":""},on:{"click":function($event){return _vm.onChangeStatusInterestPlace(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openInterestPlaceModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fa fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Sitio de Interes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onDeleteInterestPlace(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fa fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Actividad")])])]}}],null,false,1523767474)})],1)]),_c('interest-place-modal',{ref:"interestPlaceModal",attrs:{"fairId":_vm.fair.id},on:{"onClose":_vm.onClose}}),_c('confirm-dialog',{attrs:{"title":"Confirmación","description":("¿Deseas " + (Boolean(_vm.selectedInterestPlace.isActive) ? 'activar' : 'desactivar') + " el Sitio de Interes?"),"is-active":_vm.isConfirmChangeStatusInterestPlace},on:{"onClose":function($event){return _vm.closeChangeStatusInterestPlaceModal()},"onConfirm":function($event){return _vm.changeStatusGeneralActivity()}}}),_c('confirm-dialog',{attrs:{"title":"Confirmación","description":("¿Deseas eliminar el sitio de interes " + (_vm.selectedInterestPlace.name) + "?"),"is-active":_vm.isConfirmDeleteInterestPlace},on:{"onClose":function($event){return _vm.closeDeleteInterestPlaceModal()},"onConfirm":function($event){return _vm.deleteInterestPlace()}}})],1)]},proxy:true}],null,false,2623708684)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }